import { Components, Theme, ThemeOptions, createTheme } from '@mui/material';

type MuiComponents = Components<Omit<Theme, 'components'>>;

const themeMuiButton: MuiComponents['MuiButton'] = {
  styleOverrides: {
    root: {
      height: 'fit-content',
      textTransform: 'none',
      width: 'fit-content'
    }
  }
};

const themeMuiTypography: MuiComponents['MuiTypography'] = {
  styleOverrides: {
    root: {
      fontSize: '14px',
      fontFamily: 'Roboto'
    },
    h1: {
      fontSize: '42px',
      fontWeight: '400'
    },
    h2: {
      fontSize: '32px',
      fontWeight: '400'
    },
    h3: {
      fontSize: '28px',
      fontWeight: '300'
    },
    h4: {
      fontSize: '24px'
    },
    h5: {
      fontSize: '20px'
    },
    h6: {
      fontSize: '16px'
    }
  }
};

const themeComponents: MuiComponents = {
  MuiButton: themeMuiButton,
  MuiTypography: themeMuiTypography
};

const themeOptions: ThemeOptions = {
  components: themeComponents
};

export const muiTheme = createTheme(themeOptions);
