import { Box, ThemeProvider, Typography } from '@mui/material';

import './App.scss';
import { muiTheme } from './theme';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './components/home-page/HomePage';
import { SkillsPage } from './components/skills-page/SkillsPage';
import { ProjectsPage } from './components/projects-page/ProjectsPage';

export function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/skills" element={<SkillsPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}
