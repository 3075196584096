import { Box, Divider, Stack, SxProps, Theme, Typography } from '@mui/material';

import ConstructionIcon from '@mui/icons-material/Construction';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Link } from 'react-router-dom';

const boxHoverStyle: SxProps<Theme> = {
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  opacity: 0.6,
  transitionDuration: '0.2s',
  transform: 'scale(1)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
    opacity: 1,
    transitionDuration: '0.2s',
    transform: 'scale(1.2)'
  }
};

export function HomePage() {
  return (
    <Box
      sx={{
        color: '#fff',
        background: 'linear-gradient(135deg, rgba(13,46,107,1) 0%, rgba(3,129,201,1) 100%)'
      }}
    >
      <Stack direction="row" alignItems="center">
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/skills">
          <Box sx={boxHoverStyle} width="50vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
            <Stack alignItems="center" spacing="20px">
              <ConstructionIcon sx={{ fontSize: '150px' }} />
              <Typography textAlign="center" variant="h1">
                Skills
              </Typography>
              <Typography textAlign="center">
                Click here to see what
                <br />
                technologies I am familiar with
              </Typography>
            </Stack>
          </Box>
        </Link>
        <Divider
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255, 255, 255, 0.4)',
            height: '100vh'
          }}
        />
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/projects">
          <Box sx={boxHoverStyle} width="50vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
            <Stack alignItems="center" spacing="20px">
              <GitHubIcon sx={{ fontSize: '150px' }} />
              <Typography textAlign="center" variant="h1">
                Projects
              </Typography>
              <Typography textAlign="center">
                Click here to see some
                <br />
                projects I have / am working on
              </Typography>
            </Stack>
          </Box>
        </Link>
      </Stack>
    </Box>
  );
}
