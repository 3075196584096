import { Box, Divider, Rating, Stack, Typography } from '@mui/material';

type SkillItemProps = {
  description: string;
  label: string;
  rating: number;
};

function SkillItem({ description, label, rating }: SkillItemProps) {
  return (
    <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
      <Stack spacing="5px">
        <Typography variant="h3">{label}</Typography>
        <Typography width="70%">{description}</Typography>
      </Stack>
      <Rating value={rating} precision={0.5} readOnly />
    </Stack>
  );
}

export function SkillsPage() {
  return (
    <Box
    padding="20px"
    minHeight="100vh"
      sx={{
        color: '#fff',
        background: 'linear-gradient(135deg, rgba(13,46,107,1) 0%, rgba(3,129,201,1) 100%)'
      }}
    >
      <Stack spacing="10px">
        <Typography variant="h1">Skills</Typography>
        <Typography variant="h2">Programming Languages</Typography>
        <SkillItem
          description="General purpose scripting for interoperating with APIs, data organisation / visualisation and physical hardware"
          label="Python"
          rating={5}
        />
        <Divider sx={{borderColor: 'rgba(255, 255, 255, 0.4)'}} />
        <SkillItem description="Something" label="HTML / CSS" rating={5} />
        <Divider sx={{borderColor: 'rgba(255, 255, 255, 0.4)'}} />
        <SkillItem
          description="For writing either applications or libraries, like a Processing emulator or a graphics library"
          label="Java"
          rating={4.5}
        />
        <Divider sx={{borderColor: 'rgba(255, 255, 255, 0.4)'}} />
        <SkillItem description="Mainly used by me for websites, mobile / web applications or for API servers" label="Javascript / Typescript" rating={4.5} />
        <Divider sx={{borderColor: 'rgba(255, 255, 255, 0.4)'}} />
        <SkillItem description="Writing low-level applications or libraries, like creating UI windows or capturing and emulating user events" label="C / C++ / Objective-C" rating={2.5} />
        <Divider sx={{borderColor: 'rgba(255, 255, 255, 0.4)'}} />
        <SkillItem description="Something" label="Bash" rating={3} />
        <Divider sx={{borderColor: 'rgba(255, 255, 255, 0.4)'}} />
        <SkillItem description="Something" label="Rust" rating={2.5} />
        <Divider sx={{borderColor: 'rgba(255, 255, 255, 0.4)'}} />
        <SkillItem description="Something" label="C#" rating={3.5} />
        <Divider sx={{borderColor: 'rgba(255, 255, 255, 0.4)'}} />
        <SkillItem description="Something" label="Go" rating={2} />
      </Stack>
    </Box>
  );
}
